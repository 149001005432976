import { createContext, useState } from "react";
const WhatsappQueryContext = createContext({});

export const WhatsappQueryDataProvider = ({ children }) => {
  //Common Values
  const [users, setUsers] = useState([]);
  const [conversations,setConversations]=useState([])
  const [messages, setMessages] = useState([]);

  return (
    <WhatsappQueryContext.Provider
      value={{
        users,
        setUsers,
        messages,setMessages,conversations,setConversations
      }}
    >
      {children}
    </WhatsappQueryContext.Provider>
  );
};

export default WhatsappQueryContext;
