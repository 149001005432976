import AccessDenied from "../components/error-pages/AccessDenied";
import PageNotFound from "../components/error-pages/PageNotFound";
import Login from "../components/login/Login";
import { RoutesList } from "../common/SideNavData";

const privateRoutes = RoutesList?.flatMap((route) => {
  if (route.subMenu) {
    return route.subMenu.map((subRoute) => ({
      path: subRoute.link,
      component: subRoute.element,
      permission: subRoute.permissions,
    }));
  }
  return {
    path: route.link,
    component: route.element,
    permission: route.permissions,
  };
});

const deniedRoutes = [
  { path: "/access-denied", component: AccessDenied },
  { path: "*", component: PageNotFound },
];

const loginRoutes = [{ path: "/login", component: Login }];

export { privateRoutes, deniedRoutes, loginRoutes };