import { createContext, useState } from "react";
const QuestionnaireContext = createContext({});

export const QuestionnaireDataProvider = ({ children }) => {
  //Common Values
  const [isQuestionnaireShow, setIsQuestionnaireShow] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(false);

  const handleViewQuestionInfo = (question) =>{
    setIsQuestionnaireShow(true)
    setSelectedQuestion(question)
  }

  return (
    <QuestionnaireContext.Provider
      value={{
        isQuestionnaireShow, setIsQuestionnaireShow, handleViewQuestionInfo, selectedQuestion, setSelectedQuestion
      }}
    >
      {children}
    </QuestionnaireContext.Provider>
  );
};

export default QuestionnaireContext;
