import React from "react";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";
import { Navigate } from "react-router";

const PublicRoute = ({ children }) => {
  const cookies = new Cookies();
  const webAccessToken = cookies.get("access_token");

  const isValidUser = Boolean(webAccessToken);

  return !isValidUser ? children : <Navigate to="/" />;
};

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicRoute;
