import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    'test_user_data' : []
}

const UserReducers = createSlice({
    name : 'UserReducers',
    initialState : initialState,
    reducers : {
        setTestUser : (state, action) =>{
            return {
                ...state,
                test_user_data : action.payload
            }
        },
        addTestUser : (state, action) =>{
            return {
                ...state,
                test_user_data : [...state.test_user_data, action.payload]
            }
        },
        editTestUser : (state, action) =>{
            return {
                ...state,
                test_user_data : [...state.test_user_data].map(obj => obj.id === action.payload.id ? action.payload : obj)
            }
        }
    }
});


export const { setTestUser, addTestUser, editTestUser } = UserReducers.actions;

export default UserReducers.reducer;