import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    'SessionUserProfileData' : []
}

const LoginUserReducer = createSlice({
    name : 'LoginUserReducer',
    initialState : initialState,
    reducers : {
        setSessionUserProfileData : (state, action) =>{
            return {
                ...state,
                SessionUserProfileData : action.payload
            }
        }
    }
});

export const { setSessionUserProfileData } = LoginUserReducer.actions;

export default LoginUserReducer.reducer;