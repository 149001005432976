import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { privateRoutes, deniedRoutes, loginRoutes } from "./AllRoutes";
import React, { Suspense, useContext } from "react";
import Dashboard from "../components/base/Dashboard";
import MainContext from "../context/MainContext";

const CheckPermission = ({ permissions, component: Component, userRole }) => {
  if (permissions === "all" || permissions.includes(parseInt(userRole))) {
    return <Component />;
  }
  return <Navigate to="/access-denied" replace />;
};

const RouteIndex = () => {
  const { userRole } = useContext(MainContext);

  return (
    <React.Fragment>
      <Router>
        <Routes>
          {privateRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <PrivateRoute>
                  <Dashboard>
                    <Suspense fallback={<div>Loading...</div>}>
                      <CheckPermission
                        permissions={route.permission}
                        component={route.component}
                        userRole={userRole}
                      />
                    </Suspense>
                  </Dashboard>
                </PrivateRoute>
              }
              key={`private-${idx}`}
              exact={true}
            />
          ))}

          {deniedRoutes.map((route, idx) => (
            <Route
              path={route?.path}
              element={
                <PrivateRoute>
                  <Dashboard>
                    <Suspense fallback={<div>Loading...</div>}>
                      {React.createElement(route.component)}
                    </Suspense>
                  </Dashboard>
                </PrivateRoute>
              }
              key={`denied-${idx}`}
            />
          ))}

          {loginRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <PublicRoute>
                  <Suspense fallback={<div>Loading...</div>}>
                    {React.createElement(route.component)}
                  </Suspense>
                </PublicRoute>
              }
              key={`login-${idx}`}
              exact={true}
            />
          ))}
        </Routes>
      </Router>
    </React.Fragment>
  );
};

export default RouteIndex;
