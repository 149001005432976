import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import { MainContextProvider } from "./context/MainContext";
import { TokenDataProvider } from "./context/TokenContext";
import { DataProvider } from "./context/DataContext";
import { NutritionDataProvider } from "./context/NutritionContext";
import { WhatsappQueryDataProvider } from "./context/WhatsappQueryContext";
import { NotificationDataProvider } from "./context/NotificationContext";
import { QuestionnaireDataProvider } from "./context/QuestionnaireContext";

import { Provider } from "react-redux";
import dhWebAppstore from "./redux/Store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={dhWebAppstore}>
      <WhatsappQueryDataProvider>
        <MainContextProvider>
          <NotificationDataProvider>
            <TokenDataProvider>
              <DataProvider>
                <NutritionDataProvider>
                  <QuestionnaireDataProvider>
                    <App />
                  </QuestionnaireDataProvider>
                </NutritionDataProvider>
              </DataProvider>
            </TokenDataProvider>
          </NotificationDataProvider>
        </MainContextProvider>
      </WhatsappQueryDataProvider>
    </Provider>
  </React.StrictMode>
);
