import React, { useContext, useState } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGears,
  faIdCardClip,
  faKey,
  faRightFromBracket,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import MainContext from "../../context/MainContext";
import CustomizeTheme from "./CustomizeTheme";
import { sessionLogout } from "../../utils/LoginAPI";
import ChangePassword from "./ChangePassword";

const Logout = () => {//{setSigned, setMountLogin}
  const navigate = useNavigate();
  const [logged, setLogged] = useState(true);
  const cookies = new Cookies();

  const { accessToken, userProfileData, userRole, ShowNotification, setIsPreLoading, handleSetDefault  } = useContext(MainContext);

  const handleLogout = async () => {

    let requestData = { access_token: accessToken };    
    setIsPreLoading(true);

    try {
      const responseDashboardLogin = await sessionLogout(requestData);
      if (responseDashboardLogin) {
        cookies.remove("access_token");
        setLogged(false);
        // setSigned(false);
        // setMountLogin(true);
        localStorage.clear();
        handleSetDefault()
        navigate("/login");
        // window.location.reload()
      }
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", error?.message || "An error occurred");
    } finally {
      setIsPreLoading(false);
    }    
  };

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [themeShow, setThemeShow] = useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [changePasswordShow, setChangePassowrdShow] = useState(false);


  return logged ? (
    <>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton
            onClick={handleOpenUserMenu}
            sx={{ p: 0 }}
            className="user-icon"
            style={{ fontWeight: "bold" }}
          >
            <Avatar
              alt="Login User"
              src={require("../../assets/img/avatar-1.png")}
              className="mx-2"
            />
            {/* {parseInt(userRole) === 4 ? "CST Team" : "Care Team"} */}
            {userProfileData?.name || "User"}
          </IconButton>
        </Tooltip>
        <Menu
          className="user-profile-settings-dropdown"
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem className="user-profile-detail">
            <Avatar
              alt="Login User"
              src={require("../../assets/img/avatar-1.png")}
              className="me-2"
            />
            <div>              
              <p className="mb-0 user-role">
                {userProfileData?.name || "User"}
                {/* {parseInt(userRole) === 4 ? "CST Team" : "Care Team"} */}
              </p>
              <p className="mb-0 user-email">{userProfileData?.email || ""}</p>
            </div>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleCloseUserMenu();
              setThemeShow(true);
            }}
          >
            <Typography textAlign="center">
              <FontAwesomeIcon icon={faGears} className="mx-1" /> Customize
              Theme
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/profile");
              handleCloseUserMenu();
            }}
          >
            <Typography textAlign="center">
              <FontAwesomeIcon icon={faIdCardClip} className="mx-1" /> Profile
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleCloseUserMenu();
              setChangePassowrdShow(true)
            }}
          >
            <Typography textAlign="center">
              <FontAwesomeIcon icon={faKey} className="mx-1" /> Change Password
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <Typography textAlign="center">
              <FontAwesomeIcon icon={faRightFromBracket} className="mx-1" />{" "}
              Logout
            </Typography>
          </MenuItem>
        </Menu>
      </Box>

      <CustomizeTheme show={themeShow} setShow={setThemeShow} />
      <ChangePassword show={changePasswordShow} setShow={setChangePassowrdShow} />
    </>
  ) : (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleLogout}
      >
        <FontAwesomeIcon icon={faSignOut} />
      </IconButton>
    </>
  );
};

export default Logout;
